import { Pencil } from '@cfa-icons/system';
import { IconPlus } from '@tabler/icons-react';
import Footer from 'app/components/Footer/Footer';
import Navigation from 'app/components/Navigation/Navigation';
import { DeleteObjectModal } from 'app/components/shared/FormDialog/DeleteObjectModal';
import { SaveCloseModal } from 'app/components/shared/FormDialog/SaveCloseModal';
import InputRow from 'app/components/shared/InputRow';
import ApiState from 'app/constants/ApiState';
import { ACTIONS, UserMessaging } from 'app/constants/UserMessaging';
import { useAppDispatch, useAppSelector } from 'app/store';
import {
  createLeadershipMembers,
  reloadTeam,
  removeLeadershipMembers,
  removeLeadershipTeam,
  updateLeadershipMembers,
  updateLeadershipTeam,
} from 'app/store/leadership/actions';
import {
  selectLeadershipById,
  selectLeadershipMembersByLeadershipId,
} from 'app/store/leadership/selectors';
import { useLeadershipRoles } from 'app/store/roles/selectors';
import { selectLoadUserStatus, selectUsers } from 'app/store/users/selectors';
import IdentityUser from 'app/types/IdentityUser';
import { LeadershipTeam } from 'app/types/Leadership';
import Member from 'app/types/Member';
import Role from 'app/types/Role';
import { allowEditLT } from 'app/utils/hasPermissions/allowFeature';
import { useRevertEdit } from 'app/utils/hooks/useRevertEdit';
import { displayErrorToast } from 'app/utils/toasts/displayToast';
import {
  Breadcrumbs,
  Button,
  Col,
  Divider,
  Icon,
  LoadingIndicator,
  Row,
  TextField,
  TextFieldType,
  Typography,
} from 'cfa-react-components';
import { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import PageNotFound from '../PageNotFound/PageNotFound';
import './Leadership.scss';

export type InputRowType = {
  index: number;
  role: Role | null;
  user: IdentityUser | null;
  deleted: boolean;
};

export const LeadershipEditProfilePage = () => {
  const navigate = useNavigate();

  const { leadershipId } = useParams() as { leadershipId: string };
  const leadership = useAppSelector(selectLeadershipById(leadershipId));

  const [titleEditMode, setTitleEditMode] = useState(false);
  const [infoEditMode, setInfoEditMode] = useState(false);
  const titleRef = useRef<TextFieldType>(null);
  const infoRef = useRef<TextFieldType>(null);

  const [infoText, setInfoText] = useState(leadership?.info);
  const [ltName, setLtName] = useState(leadership?.name);
  const [dept, setDept] = useState('');
  const [isDisabled, setIsDisabled] = useState(true);
  const rolesList = useLeadershipRoles();
  const users = useAppSelector(selectUsers);
  const userStatus = useAppSelector(selectLoadUserStatus);

  const leadershipMembers = useAppSelector(
    selectLeadershipMembersByLeadershipId(leadershipId),
  );
  const dispatch = useAppDispatch();
  const allowLeadershipEdit: boolean = allowEditLT();

  const [inputRowList, setInputRowList] = useState<InputRowType[]>(
    [] as InputRowType[],
  );

  useEffect(() => {
    const fetchExistingLeadershipMembers = () => {
      // Fetch existing leadership members by leadership id
      // Populate inputRowList with existing leadership members if they exist
      if (leadershipMembers) {
        setInputRowList(
          leadershipMembers.map((member, index) => ({
            index: index,
            role: rolesList.find(role => role.id === member.roleId) ?? null,
            user: users.find(user => user.GUID === member.userId) ?? null,
            deleted: false,
          })),
        );
      }
    };
    fetchExistingLeadershipMembers();
  }, [users]);

  const handleAddInputRow = () => {
    setInputRowList([
      ...inputRowList,
      { index: inputRowList.length, role: null, user: null, deleted: false },
    ]);
  };

  useEffect(() => {
    setIsDisabled(!shouldEnableSaveButton());
  }, [inputRowList, ltName, infoText]);

  const shouldEnableSaveButton = () => {
    if (ltName !== leadership?.name) {
      return true;
    }

    if (Boolean(infoText) !== Boolean(leadership?.info)) {
      return true;
    }

    return inputRowList.some(item => {
      const leadershipMember = leadershipMembers?.find(
        member => member.userId === item.user?.GUID,
      );
      const roleChanged = leadershipMember?.roleId !== item?.role?.id;
      if (roleChanged && item?.role !== null) {
        return true;
      }

      return item.deleted;
    });
  };

  const handleChangeInputRow = (
    index: number,
    tempRole: Role | null,
    tempUser: IdentityUser | null,
    from: string,
  ) => {
    const tempInputRows = [...inputRowList];
    if (from == 'USER') {
      tempInputRows[index] = {
        index: tempInputRows[index].index,
        role: tempInputRows[index].role,
        user: tempUser,
        deleted: false,
      };
    }
    if (from == 'ROLE') {
      tempInputRows[index] = {
        index: tempInputRows[index].index,
        role: tempRole,
        user: tempInputRows[index].user,
        deleted: false,
      };
    }
    setInputRowList(tempInputRows);
  };

  const handleDeleteInputRow = (indexToDelete: number) => {
    const tempInputRows = [...inputRowList];
    tempInputRows[indexToDelete] = {
      index: tempInputRows[indexToDelete].index,
      role: tempInputRows[indexToDelete].role,
      user: tempInputRows[indexToDelete].user,
      deleted: true,
    };
    setInputRowList(tempInputRows);
  };

  const handleCloseLT = () => {
    if (leadership) {
      navigate(`/leadership-teams/${leadership.id}`);
    }
  };

  const saveDeleteLeadership = () => {
    if (leadershipMembers) {
      let tempMemberIds: string[] = [];
      leadershipMembers.forEach(member => {
        tempMemberIds.push(member.userId);
      });
      dispatch(removeLeadershipMembers(leadership!, tempMemberIds));
    }

    if (leadership?.members?.length === 0) {
      dispatch(removeLeadershipTeam(leadershipId))
        .then(() => navigate(`/leadership-teams`))
        .catch(e => {
          displayErrorToast(e.message, ACTIONS.deleteLeadership, leadershipId);
        });
    } else {
      displayErrorToast(
        UserMessaging.backupErrorMessaging.membersStillActive,
        ACTIONS.deleteLeadership,
        leadershipId,
      );
    }
  };

  const compareLists = (
    leadershipMembers: Member[],
    inputRowList: InputRowType[],
  ) => {
    const originalLeadershipMembers = new Map<string, string>();
    const currentMembers = new Map<string, string>();
    leadershipMembers.forEach(member => {
      originalLeadershipMembers.set(member.userId, member.roleId ?? '');
    });
    inputRowList.forEach(member => {
      if (member.deleted) {
        return;
      }
      if (member.user?.GUID) {
        currentMembers.set(member.user?.GUID, member.role?.id ?? '');
      }
    });

    const deletedList = [] as string[];
    for (const [user_id, role_id] of originalLeadershipMembers.entries()) {
      if (!currentMembers.has(user_id)) {
        deletedList.push(user_id);
      }
    }
    const addedList = [];
    const updatedList = [];
    for (const [user_id, role_id] of currentMembers.entries()) {
      if (!originalLeadershipMembers.has(user_id)) {
        addedList.push({ user_id: user_id, role_id: role_id });
      } else if (originalLeadershipMembers.get(user_id) !== role_id) {
        updatedList.push({ user_id: user_id, role_id: role_id });
      }
    }

    return { added: addedList, deleted: deletedList, updated: updatedList };
  };

  useRevertEdit(titleRef, setTitleEditMode, 'infoPencil');
  useRevertEdit(infoRef, setInfoEditMode, 'infoPencil');

  const saveUpdateLeadership = async () => {
    if (leadership) {
      let tempLeadership: LeadershipTeam = {
        ...leadership,
        name: ltName!,
        info: infoText,
      };
      if (
        leadership.name !== ltName ||
        leadership.info !== infoText ||
        leadership.deptId !== dept
      ) {
        await dispatch(
          updateLeadershipTeam(leadershipId, ltName, infoText, dept),
        ).catch(e => {
          displayErrorToast(e.message, ACTIONS.editLeadership, leadershipId);
        });
      }

      const { added, deleted, updated } = compareLists(
        leadershipMembers ?? [],
        inputRowList,
      );
      if (added.length === 0 && deleted.length === 0 && updated.length === 0) {
        return;
      }

      let newMembers: IdentityUser[] = [];
      const addedRoles: Role[] = [];
      added.forEach(async member => {
        const user = users.find(user => user.GUID === member.user_id);
        let role = rolesList.find(role => role.id === member.role_id);
        if (user && role && tempLeadership) {
          newMembers.push(user);
          addedRoles.push(role);
        }
      });

      let changedMembers: Member[] = [];
      const updatedRoles: Role[] = [];
      updated.forEach(async member => {
        const role = rolesList.find(role => role.id === member.role_id);
        const teamMember = leadershipMembers?.find(
          lMember => lMember.userId === member.user_id,
        );
        if (role && teamMember) {
          changedMembers.push(teamMember);
          updatedRoles.push(role);
        }
      });

      let memberIds: string[] = [];
      deleted.forEach(async memberId => {
        memberIds.push(memberId);
      });

      await Promise.all([
        dispatch(
          createLeadershipMembers(tempLeadership, newMembers, addedRoles),
        ).catch(e => {
          displayErrorToast(e.message, ACTIONS.createMember, leadershipId);
        }),
        dispatch(
          updateLeadershipMembers(tempLeadership, changedMembers, updatedRoles),
        ).catch(e => {
          displayErrorToast(e.message, ACTIONS.editMember, leadershipId);
        }),
        dispatch(removeLeadershipMembers(tempLeadership, memberIds)).catch(
          e => {
            displayErrorToast(e.message, ACTIONS.deleteMember, leadershipId);
          },
        ),
      ]).catch(e => {
        console.log(e);
      });
      dispatch(reloadTeam(leadershipId));
    }

    navigate(`/leadership-teams/${leadershipId}`);
  };

  if (userStatus.state == ApiState.IDLE) {
    return leadership ? (
      <>
        <Navigation />
        <div className="main-container">
          <div className="top-section">
            <Breadcrumbs
              breadcrumbs={[
                {
                  label: 'Leadership Teams',
                  onClick: function backToLeadership() {
                    navigate('/leadership-teams');
                  },
                },
                {
                  label: leadership?.name,
                },
              ]}
            />
          </div>
          {/* TODO: Title section can be its own components */}
          <div className="title-section">
            {titleEditMode ? (
              <TextField
                multiline
                rows={1}
                required
                value={ltName}
                textAlign="start"
                maxLength={50}
                onChange={e => setLtName(e.target.value)}
                ref={titleRef}
                style={{
                  marginLeft: '20px',
                  marginRight: '20px',
                }}
              />
            ) : (
              <Typography
                variant="h2"
                color="primary"
                fontWeight="bold"
                gutterBottom
                style={{
                  paddingLeft: '12px',
                }}
                ref={titleRef}
                onClick={() => setTitleEditMode(!titleEditMode)}
              >
                {ltName}
              </Typography>
            )}
            <Icon
              className="infoPencil"
              icon={Pencil}
              size="md"
              color="grey"
              onClick={() => setTitleEditMode(!titleEditMode)}
              style={{ position: 'relative', left: '15px', bottom: '10px' }}
            />
          </div>
          {/* TODO: Edit Group Description can be it's own component */}
          <div className="body-section">
            {infoEditMode ? (
              <TextField
                multiline
                fullWidth
                rows={0}
                value={infoText}
                onChange={e => setInfoText(e.target.value)}
                ref={infoRef}
                placeholder="Add a description for your leadership team here"
                style={{
                  marginTop: '00px',
                  marginLeft: '30px',
                  marginRight: '30px',
                  overflow: 'scroll',
                  overflowX: 'hidden',
                  overflowY: 'auto',
                }}
              />
            ) : (
              <Typography
                variant="body1"
                color="secondary"
                fontWeight="medium"
                align="center"
                gutterBottom
                ref={infoRef}
                onClick={() => setInfoEditMode(true)}
                style={{
                  paddingLeft: '12px',
                  marginTop: '0px',
                  marginRight: '20px',
                  overflow: 'scroll',
                  overflowX: 'hidden',
                  overflowY: 'auto',
                  color: infoText ? 'inherit' : 'grey',
                  fontStyle: infoText ? 'inherit' : 'italic',
                }}
              >
                {infoText
                  ? infoText
                  : 'Add a description for your leadership team here'}
              </Typography>
            )}
            {infoEditMode ? (
              <Icon
                className="infoPencil"
                icon={Pencil}
                size="md"
                color="grey"
                onClick={() => setInfoEditMode(!infoEditMode)}
                style={{ position: 'relative', top: 'px' }}
              />
            ) : (
              <Icon
                className="infoPencil"
                icon={Pencil}
                size="md"
                color="grey"
                onClick={() => setInfoEditMode(!infoEditMode)}
                style={{ position: 'relative', top: '-5px' }}
              />
            )}
          </div>
          <div
            style={{
              width: '800px',
              alignSelf: 'center',
            }}
          >
            <Divider
              variant="fullLength"
              orientation="horizontal"
              style={{ marginBottom: '10px', marginTop: '10px' }}
            ></Divider>
            <Col sm={12}>
              <Row
                style={{
                  marginBottom: '1rem',
                  marginTop: '15px',
                  justifyContent: 'center',
                  maxHeight: '500px',
                  overflowY: 'scroll',
                }}
              >
                {inputRowList.map(item => {
                  if (item.deleted != true) {
                    return (
                      <InputRow
                        key={item.index}
                        tempUser={item.user}
                        tempRole={item.role}
                        onDelete={handleDeleteInputRow}
                        rolesList={rolesList}
                        index={item.index}
                        handleInputChange={handleChangeInputRow}
                        originalLength={leadershipMembers?.length}
                      />
                    );
                  }
                  return <></>;
                })}
              </Row>
            </Col>
            <Divider
              variant="fullLength"
              orientation="horizontal"
              style={{ marginBottom: '10px' }}
            ></Divider>
            <div className="saveAndCloseButtons">
              <div
                onClick={handleAddInputRow}
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  marginLeft: '-10px',
                }}
              >
                <Icon
                  icon={IconPlus}
                  style={{
                    textAlign: 'center',
                    cursor: 'pointer',
                    justifyContent: 'center',
                  }}
                  height="1.5em"
                  width="1.5em"
                />
                <h4
                  style={{
                    color: '#004F71',
                    marginLeft: '.75rem',
                    marginTop: '.15rem',
                    cursor: 'pointer',
                  }}
                >
                  {' '}
                  Add Leadership Team Member
                </h4>
              </div>
              <div
                style={{
                  marginTop: '50px',
                }}
              >
                <SaveCloseModal
                  isDisabled={false}
                  handleClose={handleCloseLT}
                  handleSave={saveUpdateLeadership}
                ></SaveCloseModal>
                <Button
                  color="primary"
                  onClick={saveUpdateLeadership}
                  size="md"
                  variant="filled"
                  disabled={isDisabled}
                  style={{
                    marginLeft: '5px',
                  }}
                >
                  Save
                </Button>
              </div>
            </div>
          </div>
          <DeleteObjectModal
            type="Leadership Team"
            itemName={leadership.name}
            handleDelete={saveDeleteLeadership}
            handleClose={function noRefCheck() {}}
            allowDelete={allowLeadershipEdit}
          />
        </div>
        <Footer></Footer>
      </>
    ) : (
      <PageNotFound></PageNotFound>
    );
  } else {
    return (
      <div className="loading">
        <LoadingIndicator variant={'page'}></LoadingIndicator>
      </div>
    );
  }
};
