import { Pencil } from '@cfa-icons/system';
import { IconPlus } from '@tabler/icons-react';
import Footer from 'app/components/Footer/Footer';
import Navigation from 'app/components/Navigation/Navigation';
import { DeleteObjectModal } from 'app/components/shared/FormDialog/DeleteObjectModal';
import { SaveCloseModal } from 'app/components/shared/FormDialog/SaveCloseModal';
import InputRow from 'app/components/shared/InputRow';
import RoleType from 'app/constants/ApiRole';
import ApiState from 'app/constants/ApiState';
import { ACTIONS, UserMessaging } from 'app/constants/UserMessaging';
import { useAppDispatch, useAppSelector } from 'app/store';
import { selectSelectedDeptTab } from 'app/store/app/selectors';
import {
  selectDepartmentById,
  selectDepartments,
} from 'app/store/dept/selectors';
import {
  createPodMembers,
  reloadPod,
  removePod,
  removePodMembers,
  updatePod,
  updatePodMembers,
} from 'app/store/pods/actions';
import {
  selectPodById,
  selectPodMembersByPodId,
} from 'app/store/pods/selectors';
import { usePodRoles } from 'app/store/roles/selectors';
import { selectLoadUserStatus, selectUsers } from 'app/store/users/selectors';
import Department from 'app/types/Department';
import IdentityUser from 'app/types/IdentityUser';
import Member from 'app/types/Member';
import Pod from 'app/types/Pod';
import Role from 'app/types/Role';
import { allowEditPodMember } from 'app/utils/hasPermissions/allowFeature';
import { useRevertEdit } from 'app/utils/hooks/useRevertEdit';
import { displayErrorToast } from 'app/utils/toasts/displayToast';
import {
  Breadcrumbs,
  Button,
  Col,
  Divider,
  Dropdown,
  Icon,
  LoadingIndicator,
  Row,
  TextField,
  TextFieldType,
  Typography,
} from 'cfa-react-components';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import PageNotFound from '../PageNotFound/PageNotFound';

export type InputRowType = {
  index: number;
  role: Role | null;
  user: IdentityUser | null;
  deleted: boolean;
};

export const PodsEditProfilePage = () => {
  const navigate = useNavigate();

  const { podId } = useParams() as { podId: string };
  let pod = useAppSelector(selectPodById(podId));
  const [titleEditMode, setTitleEditMode] = useState(false);
  const [infoEditMode, setInfoEditMode] = useState(false);
  const titleRef = useRef<TextFieldType>(null);
  const infoRef = useRef<TextFieldType>(null);

  const [infoText, setInfoText] = useState(pod?.info);
  const [podName, setPodName] = useState(pod?.name);
  const userStatus = useAppSelector(selectLoadUserStatus);

  const podMembers = useAppSelector(selectPodMembersByPodId(podId));
  const dispatch = useAppDispatch();
  const allowPodEdit: boolean = allowEditPodMember();
  const users = useAppSelector(selectUsers);
  const rolesList = usePodRoles();
  const [isDisabled, setIsDisabled] = useState(true);
  const deptList = useAppSelector(selectDepartments);
  const selectedDeptTab = useAppSelector(selectSelectedDeptTab);
  const currentDept = useAppSelector(selectDepartmentById(selectedDeptTab));
  const [subDept, setSubDept] = React.useState<Department | null>(
    currentDept || null,
  );
  const [loading, setLoading] = useState(false);

  const [inputRowList, setInputRowList] = useState<InputRowType[]>(
    [] as InputRowType[],
  );

  useEffect(() => {
    const fetchExistingPodMembers = () => {
      if (podMembers) {
        setInputRowList(
          podMembers.map((member, index) => ({
            index: index,
            role: rolesList.find(role => role.id === member.roleId) ?? null,
            user: users.find(user => user.GUID === member.userId) ?? null,
            deleted: false,
          })),
        );
      }
    };
    fetchExistingPodMembers();
  }, [users]);

  useRevertEdit(titleRef, setTitleEditMode, 'infoPencil');
  useRevertEdit(infoRef, setInfoEditMode, 'infoPencil');

  const handleAddInputRow = () => {
    setInputRowList([
      ...inputRowList,
      { index: inputRowList.length, role: null, user: null, deleted: false },
    ]);
  };

  useEffect(() => {
    if (currentDept !== undefined) {
      setSubDept(currentDept);
    }
  }, [currentDept]);

  const shouldEnableSaveButton = () => {
    const doAllNamesExist = inputRowList.every(item => item?.user?.displayName);
    if (!doAllNamesExist) {
      return false;
    }

    if (podName !== pod?.name) {
      return true;
    }

    if (Boolean(infoText) !== Boolean(pod?.info)) {
      return true;
    }

    if (subDept?.id !== pod?.deptId) {
      return true;
    }

    return inputRowList.some(item => {
      const podMember = podMembers?.find(
        member => member.userId === item.user?.GUID,
      );
      const roleChanged = podMember?.roleId !== item?.role?.id;

      if (item?.user?.displayName && !podMember?.name) {
        return true;
      }

      if (roleChanged && item?.role !== null) {
        return true;
      }

      return item.deleted;
    });
  };

  useEffect(() => {
    setIsDisabled(!shouldEnableSaveButton());
  }, [inputRowList, podName, infoText, subDept]);

  const handleChangeInputRow = (
    index: number,
    tempRole: Role | null,
    tempUser: IdentityUser | null,
    from: string,
  ) => {
    const tempInputRows = [...inputRowList];
    if (from == 'USER') {
      tempInputRows[index] = {
        index: tempInputRows[index].index,
        role: tempInputRows[index].role,
        user: tempUser,
        deleted: false,
      };
    }
    if (from == 'ROLE') {
      tempInputRows[index] = {
        index: tempInputRows[index].index,
        role: tempRole,
        user: tempInputRows[index].user,
        deleted: false,
      };
    }
    setInputRowList(tempInputRows);
  };

  const handleDeleteInputRow = (
    indexToDelete: number,
    user: IdentityUser | null,
  ) => {
    // find if the row we are deleting has already been saved to the pod
    const podMember = podMembers?.find(member => member.userId === user?.GUID);
    // mark the row as deleted to trigger UI change
    const tempInputRows = [...inputRowList];
    tempInputRows[indexToDelete] = {
      index: tempInputRows[indexToDelete].index,
      role: tempInputRows[indexToDelete].role,
      user: tempInputRows[indexToDelete].user,
      deleted: true,
    };
    setInputRowList(tempInputRows);
    if (!podMember) {
      // if the row was never saved to the pod, remove the row from the list in order to preserve the correct state of the rows
      tempInputRows.splice(indexToDelete, 1);
      setInputRowList(tempInputRows);
    }
  };

  const handleClosePod = () => {
    if (pod) {
      navigate(`/pods/${pod.id}`);
    }
  };

  const saveDeletePod = async () => {
    if (podMembers) {
      let tempMemberIds: string[] = [];
      podMembers.forEach(member => {
        tempMemberIds.push(member.userId);
      });
      await dispatch(removePodMembers(pod!, tempMemberIds)).catch(error => {
        displayErrorToast(error.message, ACTIONS.deleteMember, podId);
      });
      // TODO: Add validation on backend to check that there are no active members.
    }

    if (pod?.members?.length === 0) {
      dispatch(removePod(podId))
        .then(() => {
          navigate('/pods');
        })
        .catch(error => {
          displayErrorToast(error.message, ACTIONS.deletePod, podId);
        });
    } else {
      displayErrorToast(
        UserMessaging.backupErrorMessaging.membersStillActive,
        ACTIONS.deletePod,
        podId,
      );
    }
  };

  const compareLists = (podMembers: Member[], inputRowList: InputRowType[]) => {
    const originalPodMembers = new Map<string, string>();
    const currentMembers = new Map<string, string>();
    podMembers.forEach(member => {
      originalPodMembers.set(member.userId, member.roleId ?? '');
    });
    inputRowList.forEach(member => {
      if (member.deleted) {
        return;
      }
      if (member.user?.GUID) {
        currentMembers.set(member.user?.GUID, member.role?.id ?? '');
      }
    });

    const deletedList = [] as string[];
    for (const [user_id, role_id] of originalPodMembers.entries()) {
      if (!currentMembers.has(user_id)) {
        deletedList.push(user_id);
      }
    }
    const addedList = [];
    const updatedList = [];
    for (const [user_id, role_id] of currentMembers.entries()) {
      if (!originalPodMembers.has(user_id)) {
        addedList.push({ user_id: user_id, role_id: role_id });
      } else if (originalPodMembers.get(user_id) !== role_id) {
        updatedList.push({ user_id: user_id, role_id: role_id });
      }
    }
    return { added: addedList, deleted: deletedList, updated: updatedList };
  };

  const updateNameDescription = async () => {
    if (
      pod &&
      (podName !== pod.name ||
        infoText !== pod.info ||
        subDept?.id !== pod.deptId)
    ) {
      dispatch(
        updatePod(pod, podName ?? '', infoText ?? '', subDept?.id!),
      ).catch(error => {
        displayErrorToast(error.message, ACTIONS.editPod, pod?.id);
      });
    }
  };

  const updatePeople = async () => {
    if (!pod) {
      return;
    }
    let tempPod: Pod = { ...pod, name: podName!, info: infoText };
    const { added, deleted, updated } = compareLists(
      podMembers ?? [],
      inputRowList,
    );
    if (added.length === 0 && deleted.length === 0 && updated.length === 0) {
      return;
    }

    let newMembers: IdentityUser[] = [];
    const addedRoles: Role[] = [];
    added.forEach(async member => {
      let user = users.find(user => user.GUID === member.user_id);
      let role = rolesList.find(role => role.id === member.role_id);
      if (user && pod) {
        newMembers.push(user);
        if (role) {
          addedRoles.push(role);
        } else {
          const emptyRole: Role = {
            id: '',
            name: '',
            type: RoleType.POD_ROLE,
            createdAt: new Date(),
            updatedAt: undefined,
            deletedAt: undefined,
          };
          addedRoles.push(emptyRole);
        }
      }
    });

    let memberIds: string[] = [];
    deleted.forEach(async memberId => {
      memberIds.push(memberId);
    });

    let changedMembers: Member[] = [];
    const updatedRoles: Role[] = [];

    updated.forEach(async member => {
      const role = rolesList.find(role => role.id === member.role_id);
      const podMember = podMembers?.find(
        cMember => cMember.userId === member.user_id,
      );
      if (role && podMember && pod) {
        changedMembers.push(podMember);
        updatedRoles.push(role);
      }
    });

    await Promise.all([
      dispatch(removePodMembers(tempPod, memberIds)).catch(error => {
        displayErrorToast(error.message, ACTIONS.deleteMember, tempPod.id);
      }),
      dispatch(createPodMembers(newMembers, tempPod, addedRoles)).catch(
        error => {
          displayErrorToast(error.message, ACTIONS.createMember, tempPod.id);
        },
      ),
      dispatch(updatePodMembers(tempPod, changedMembers, updatedRoles)).catch(
        error => {
          displayErrorToast(error.message, ACTIONS.editMember, tempPod.id);
        },
      ),
    ]).catch(error => {
      console.log(error.message);
    });

    await dispatch(reloadPod(pod.id));
  };

  const saveUpdatePod = async () => {
    setLoading(true);
    updateNameDescription();
    await updatePeople();
    navigate(`/pods/${podId}`);
    setLoading(false);
  };

  if (userStatus.state == ApiState.LOADING || loading) {
    return (
      <div className="loading">
        <LoadingIndicator variant={'page'}></LoadingIndicator>
      </div>
    );
  }

  return pod ? (
    <>
      <Navigation />
      <div className="main-container">
        <div className="top-section">
          <Breadcrumbs
            breadcrumbs={[
              {
                label: 'Pods',
                onClick: function backToLeadership() {
                  navigate('/pods');
                },
              },
              {
                label: pod?.name,
              },
            ]}
          />
        </div>
        <div className="title-section">
          {titleEditMode ? (
            <TextField
              multiline
              rows={1}
              required
              value={podName}
              textAlign="start"
              maxLength={50}
              onChange={e => setPodName(e.target.value)}
              ref={titleRef}
              style={{
                marginLeft: '20px',
                marginRight: '20px',
              }}
              data-cy="pod-name-edit"
            />
          ) : (
            <Typography
              variant="h2"
              color="primary"
              fontWeight="bold"
              gutterBottom
              style={{
                paddingLeft: '12px',
              }}
              onClick={() => setTitleEditMode(!titleEditMode)}
              ref={titleRef}
              data-cy="pod-name-no-edit"
            >
              {podName}
            </Typography>
          )}
          <Icon
            data-cy="edit-pencil-name"
            className="infoPencil"
            icon={Pencil}
            size="md"
            color="grey"
            onClick={() => setTitleEditMode(!titleEditMode)}
            style={{ position: 'relative', left: '15px', bottom: '10px' }}
          />
        </div>
        <div
          style={{
            marginTop: '-1.5rem',
            marginBottom: '20px',
            display: 'flex',
            alignSelf: 'center',
            justifyContent: 'center',
            alignContent: 'center',
          }}
        >
          <Dropdown
            onChange={e => {
              setSubDept(e);
            }}
            style={{
              maxWidth: 'unset',
              width: '300px',
            }}
            options={deptList}
            getOptionId={option => option.id}
            getOptionText={option => option.name}
            renderOption={option => option.name}
            placeholder="Select a subdepartment"
            value={subDept}
          />
        </div>
        <div className="body-section">
          {infoEditMode ? (
            <TextField
              multiline
              fullWidth
              rows={0}
              value={infoText}
              onChange={e => setInfoText(e.target.value)}
              placeholder="Add a description for your pod here"
              ref={infoRef}
              style={{
                marginLeft: '30px',
                marginRight: '30px',
                overflow: 'scroll',
                overflowX: 'hidden',
                overflowY: 'auto',
              }}
              data-cy="pod-info-edit"
            />
          ) : (
            <Typography
              variant="body1"
              color="secondary"
              fontWeight="medium"
              align="center"
              gutterBottom
              onClick={() => setInfoEditMode(true)}
              ref={infoRef}
              style={{
                paddingLeft: '12px',
                marginTop: '0px',
                marginRight: '20px',
                overflow: 'scroll',
                overflowX: 'hidden',
                overflowY: 'auto',
                color: infoText ? 'inherit' : 'grey',
                fontStyle: infoText ? 'inherit' : 'italic',
              }}
              data-cy="pod-info-no-edit"
            >
              {infoText ? infoText : 'Add a description for your pod here'}
            </Typography>
          )}
          {infoEditMode ? (
            <Icon
              className="infoPencil"
              icon={Pencil}
              size="md"
              color="grey"
              onClick={() => setInfoEditMode(!infoEditMode)}
              style={{ position: 'relative', top: 'px' }}
              data-cy="edit-pencil-info"
            />
          ) : (
            <Icon
              className="infoPencil"
              icon={Pencil}
              size="md"
              color="grey"
              onClick={() => setInfoEditMode(!infoEditMode)}
              style={{ position: 'relative', top: '-5px' }}
              data-cy="edit-pencil-info"
            />
          )}
        </div>
        <div
          style={{
            width: '800px',
            alignSelf: 'center',
          }}
        >
          <Divider
            variant="fullLength"
            orientation="horizontal"
            style={{ marginBottom: '10px', marginTop: '10px' }}
          ></Divider>
          <Col sm={12}>
            <Row
              style={{
                marginBottom: '1rem',
                marginTop: '15px',
                justifyContent: 'center',
                maxHeight: '500px',
                overflowY: 'scroll',
              }}
            >
              {inputRowList.map(item => {
                if (item.deleted != true) {
                  return (
                    <InputRow
                      key={item.index}
                      tempUser={item.user}
                      tempRole={item.role}
                      onDelete={() =>
                        handleDeleteInputRow(item.index, item?.user)
                      }
                      rolesList={rolesList}
                      index={item.index}
                      handleInputChange={handleChangeInputRow}
                      originalLength={podMembers?.length}
                    />
                  );
                }
                return <></>;
              })}
            </Row>
          </Col>
          <Divider
            variant="fullLength"
            orientation="horizontal"
            style={{ marginBottom: '10px' }}
          ></Divider>
          <div className="saveAndCloseButtons">
            <div
              onClick={handleAddInputRow}
              style={{
                display: 'flex',
                flexDirection: 'row',
                marginRight: '80px',
              }}
              data-cy="add-member-btn"
            >
              <Icon
                icon={IconPlus}
                style={{
                  textAlign: 'center',
                  cursor: 'pointer',
                  justifyContent: 'center',
                }}
                height="1.5em"
                width="1.5em"
              />
              <h4
                style={{
                  color: '#004F71',
                  marginLeft: '.75rem',
                  marginTop: '.15rem',
                  cursor: 'pointer',
                }}
              >
                {' '}
                Add Pod Member
              </h4>
            </div>
            <div
              style={{
                marginTop: '50px',
              }}
            >
              <SaveCloseModal
                isDisabled={isDisabled}
                handleClose={handleClosePod}
                handleSave={saveUpdatePod}
              />
              <Button
                color="primary"
                onClick={saveUpdatePod}
                size="md"
                variant="filled"
                disabled={isDisabled}
                style={{
                  marginLeft: '5px',
                }}
                data-cy="pod-edit-save-btn"
              >
                Save
              </Button>
            </div>
          </div>
        </div>
        <DeleteObjectModal
          type="Pod"
          itemName={pod.name}
          handleDelete={saveDeletePod}
          handleClose={function noRefCheck() {}}
          allowDelete={allowPodEdit}
        />
      </div>
      <Footer></Footer>
    </>
  ) : (
    <PageNotFound></PageNotFound>
  );
};
