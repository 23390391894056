import { Pencil } from '@cfa-icons/system';
import Footer from 'app/components/Footer/Footer';
import Navigation from 'app/components/Navigation/Navigation';
import { DeleteObjectModal } from 'app/components/shared/FormDialog/DeleteObjectModal';
import { SaveCloseModal } from 'app/components/shared/FormDialog/SaveCloseModal';
import { ACTIONS } from 'app/constants/UserMessaging';
import { useAppDispatch, useAppSelector } from 'app/store';
import { removeGuild, updateGuild } from 'app/store/guilds/actions';
import { selectGuildByID } from 'app/store/guilds/selectors';
import { allowEditGuild } from 'app/utils/hasPermissions/allowFeature';
import { useRevertEdit } from 'app/utils/hooks/useRevertEdit';
import { displayErrorToast } from 'app/utils/toasts/displayToast';
import {
  Breadcrumbs,
  Button,
  Icon,
  LoadingIndicator,
  TextField,
  TextFieldType,
  Typography,
} from 'cfa-react-components';
import { useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import './Guilds.scss';

export const GuildEditProfilePage = () => {
  const navigate = useNavigate();

  const { guildId } = useParams() as { guildId: string };
  const guild = useAppSelector(selectGuildByID(guildId));

  const [titleEditMode, setTitleEditMode] = useState(false);
  const [infoEditMode, setInfoEditMode] = useState(false);
  const titleRef = useRef<TextFieldType>(null);
  const infoRef = useRef<TextFieldType>(null);

  const [infoText, setInfoText] = useState(guild?.info);
  const [titleText, setTitleText] = useState(guild?.name);
  const dispatch = useAppDispatch();
  const allowGuildEdit: boolean = allowEditGuild();

  const saveUpdateGuild = () => {
    dispatch(updateGuild(guildId, titleText, infoText))
      .then(() => {
        closeInfo();
      })
      .catch(error => {
        displayErrorToast(error.message, ACTIONS.editGuild, guildId);
      });
  };

  const closeInfo = () => {
    if (guild) {
      navigate(`/guilds/${guild.id}`);
    }
  };

  const saveDeleteGuild = () => {
    dispatch(removeGuild(guildId))
      .then(() => {
        navigate('/guilds');
      })
      .catch(error => {
        displayErrorToast(error.message, ACTIONS.deleteGuild);
      });
  };

  useRevertEdit(titleRef, setTitleEditMode, 'infoPencil');
  useRevertEdit(infoRef, setInfoEditMode, 'infoPencil');

  return guild ? (
    <>
      <Navigation />
      <div className="main-container">
        <div className="top-section">
          <Breadcrumbs
            breadcrumbs={[
              {
                label: 'Guilds',
                onClick: function backToGuilds() {
                  navigate('/guilds');
                },
              },
              {
                label: guild?.name,
              },
            ]}
          />
          <div className="saveAndCloseButtons">
            <SaveCloseModal
              handleClose={closeInfo}
              handleSave={saveUpdateGuild}
            />
            <Button
              color="primary"
              onClick={saveUpdateGuild}
              size="md"
              variant="filled"
              style={{
                marginLeft: '5px',
              }}
              data-cy="save-guild"
            >
              Save
            </Button>
          </div>
        </div>
        <div className="title-section">
          {titleEditMode ? (
            <TextField
              multiline
              rows={1}
              required
              value={titleText}
              textAlign="start"
              maxLength={50}
              onChange={e => setTitleText(e.target.value)}
              ref={titleRef}
              style={{
                marginLeft: '20px',
                marginRight: '20px',
              }}
              data-cy="guild-title-edit-box"
            />
          ) : (
            <Typography
              variant="h2"
              color="primary"
              fontWeight="bold"
              gutterBottom
              ref={titleRef}
              style={{
                paddingLeft: '12px',
              }}
              onClick={() => setTitleEditMode(!titleEditMode)}
            >
              {titleText}
            </Typography>
          )}
          <Icon
            className="infoPencil"
            icon={Pencil}
            size="md"
            color="grey"
            onClick={() => setTitleEditMode(!titleEditMode)}
            style={{ position: 'relative', left: '15px', bottom: '10px' }}
            data-cy="guild-title-pencil"
          />
        </div>
        <div className="body-section">
          {infoEditMode ? (
            <TextField
              multiline
              fullWidth
              rows={10}
              value={infoText}
              onChange={e => setInfoText(e.target.value)}
              placeholder="Add a description for your guild here"
              ref={infoRef}
              style={{
                minHeight: '50vh',
                marginTop: '30px',
                marginLeft: '30px',
                marginRight: '30px',
                overflow: 'scroll',
                overflowX: 'hidden',
                overflowY: 'auto',
              }}
              data-cy="guild-desc-edit-box"
            />
          ) : (
            <Typography
              variant="body1"
              color="secondary"
              fontWeight="medium"
              align="center"
              gutterBottom
              onClick={() => setInfoEditMode(true)}
              ref={infoRef}
              style={{
                paddingLeft: '12px',
                minHeight: '50vh',
                marginTop: '30px',
                marginRight: '20px',
                overflow: 'scroll',
                overflowX: 'hidden',
                overflowY: 'auto',
                color: infoText ? 'inherit' : 'grey',
                fontStyle: infoText ? 'inherit' : 'italic',
              }}
            >
              {infoText ? infoText : 'Add a description for your guild here'}
            </Typography>
          )}
          <Icon
            className="infoPencil"
            icon={Pencil}
            size="md"
            color="grey"
            onClick={() => setInfoEditMode(!infoEditMode)}
            style={{ position: 'relative', top: '25px' }}
            data-cy="guild-desc-pencil"
          />
        </div>
        <DeleteObjectModal
          type="Guild"
          itemName={guild.name}
          handleDelete={saveDeleteGuild}
          handleClose={function noRefCheck() {}}
          allowDelete={allowGuildEdit}
        />
      </div>
      <Footer />
    </>
  ) : (
    <LoadingIndicator variant={'page'}></LoadingIndicator>
  );
};
