import React, { useEffect, useRef, useState } from 'react';

import { IconPlus } from '@tabler/icons-react';
import { ACTIONS } from 'app/constants/UserMessaging';
import { useAppDispatch, useAppSelector } from 'app/store';
import { selectSelectedDeptTab } from 'app/store/app/selectors';
import {
  selectDepartmentById,
  selectDepartments,
} from 'app/store/dept/selectors';
import { createPod } from 'app/store/pods/actions';
import { usePodRoles } from 'app/store/roles/selectors';
import Department from 'app/types/Department';
import { IdentityUser } from 'app/types/IdentityUser';
import Member from 'app/types/Member';
import MemberStatus from 'app/types/MemberStatus';
import MemberType from 'app/types/MemberType';
import { Role } from 'app/types/Role';
import { displayErrorToast } from 'app/utils/toasts/displayToast';
import {
  Button,
  Col,
  Container,
  Dropdown,
  Icon,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  TextField,
  Tooltip,
} from 'cfa-react-components';
import '../../../../App.scss';
import InputRow from '../InputRow';

export type CreateProps = {
  allowCreate: boolean;
};

export type InputRowType = {
  id: number;
  role: Role | null;
  user: IdentityUser | null;
  deleted: boolean;
};

export const CreatePodButton = ({ allowCreate }: CreateProps) => {
  const [open, setOpen] = useState(false);
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const containerRef = useRef<HTMLDivElement>(null);
  const [isDisabled, setIsDisabled] = useState(true);
  const [inputRowList, setInputRowList] = useState<InputRowType[]>([
    { id: 0, role: null, user: null, deleted: false },
  ]);
  const deptList = useAppSelector(selectDepartments);
  const selectedDeptTab = useAppSelector(selectSelectedDeptTab);
  const currentDept = useAppSelector(selectDepartmentById(selectedDeptTab));
  const [subDept, setSubDept] = React.useState<Department | null>(
    currentDept || null,
  );
  const roleList = usePodRoles();

  const dispatch = useAppDispatch();

  const handleAddInputRow = () => {
    setInputRowList([
      ...inputRowList,
      { id: inputRowList.length, role: null, user: null, deleted: false },
    ]);
  };

  useEffect(() => {
    if (currentDept !== undefined) {
      setSubDept(currentDept);
    }
  }, [currentDept]);

  useEffect(() => {
    setIsDisabled(validateInputs());
  }, [inputRowList, name]);

  const handleChangeInputRow = (
    index: number,
    tempRole: Role | null,
    tempUser: IdentityUser | null,
    from: string,
  ) => {
    const tempInputRows = [...inputRowList];
    if (from === 'USER') {
      tempInputRows[index] = {
        id: tempInputRows[index].id,
        role: tempInputRows[index].role,
        user: tempUser,
        deleted: false,
      };
    }
    if (from === 'ROLE') {
      tempInputRows[index] = {
        id: tempInputRows[index].id,
        role: tempRole,
        user: tempInputRows[index].user,
        deleted: false,
      };
    }
    setInputRowList(tempInputRows);
  };

  const handleDeleteInputRow = (indexToDelete: number) => {
    const tempInputRows = [...inputRowList];
    tempInputRows[indexToDelete] = {
      id: tempInputRows[indexToDelete].id,
      role: tempInputRows[indexToDelete].role,
      user: tempInputRows[indexToDelete].user,
      deleted: true,
    };
    setInputRowList(tempInputRows);
  };

  const validateInputs = () => {
    return inputRowList
      .filter(item => {
        return item.deleted == false;
      })
      .some(item => {
        return item.user == null || name == '';
      });
  };

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollTop = containerRef.current.scrollHeight;
    }
  }, [inputRowList.length]);

  const handleClose = () => {
    setName('');
    setDescription('');
    setOpen(false);
    resetInputList();
  };
  const resetInputList = () => {
    setInputRowList([{ id: 0, role: null, user: null, deleted: false }]);
  };

  const handleSaveClose = async () => {
    const podMemberList: Member[] = [];
    inputRowList.forEach(item => {
      if (item.deleted == false) {
        const member: Member = {
          userId: item?.user?.GUID ?? '',
          groupId: 'temp',
          type: MemberType.PODS,
          name: item.user?.displayName ?? '',
          isContingent: item.user?.AUDIENCE_TAG === 'CONTRACTOR_AUDIENCE',
          status: MemberStatus.ACTIVE,
          roleId: item.role?.id,
        };
        podMemberList.push(member);
      }
    });
    dispatch(createPod(description, name, subDept?.id!, podMemberList))
      .then(() => {
        handleClose();
      })
      .catch(error => {
        displayErrorToast(error.message, ACTIONS.createPod, name);
      });
  };

  return (
    <>
      {allowCreate ? (
        <Button
          size="sm"
          onClick={e => setOpen(true)}
          style={{
            height: '40px',
          }}
          disabled={!allowCreate}
        >
          Create Pod
        </Button>
      ) : (
        <Tooltip content="Insufficient Permissions">
          <span>
            <Button
              size="sm"
              onClick={e => setOpen(true)}
              style={{
                height: '40px',
              }}
              disabled={!allowCreate}
            >
              Create Pod
            </Button>
          </span>
        </Tooltip>
      )}
      <Modal
        show={open}
        onClose={handleClose}
        size="lg"
        scrollMode="modal-body"
      >
        <React.Fragment key=".0">
          <ModalHeader>Create New Pod</ModalHeader>
          <ModalBody
            style={{
              paddingRight: '3px',
              overflowX: 'hidden',
            }}
          >
            To create a new pod, enter a pod name and add pod members.
            <Container
              ref={containerRef}
              style={{
                textAlign: 'left',
                maxHeight: '50vh',
                maxWidth: '',
                overflowY: 'scroll',
              }}
            >
              <Row
                style={{
                  marginRight: '30px',
                  marginBottom: '1rem',
                  marginTop: '1rem',
                }}
              >
                <Col sm={12}>
                  <TextField
                    label="Pod Name"
                    required
                    onChange={e => setName(e.target.value)}
                    placeholder="Pod Name"
                    fullWidth
                    value={name}
                  />
                </Col>
                <Col sm={12} style={{ marginTop: '.5rem' }}>
                  <Dropdown
                    label="Subdepartment"
                    onChange={e => {
                      setSubDept(e);
                    }}
                    options={deptList}
                    getOptionId={option => option.id}
                    getOptionText={option => option.name}
                    renderOption={option => option.name}
                    placeholder="Select a subdepartment"
                    value={subDept}
                    fullWidth
                  />
                </Col>
                <Col sm={12} style={{ marginTop: '.5rem' }}>
                  <TextField
                    label="Pod Description"
                    onChange={e => setDescription(e.target.value)}
                    placeholder="Enter a description for the Pod here"
                    multiline
                    rows={3}
                    fullWidth
                    value={description}
                  />
                </Col>
              </Row>
              <Col sm={12}>
                <Row
                  style={{
                    marginBottom: '1rem',
                    marginTop: '1.65rem',
                  }}
                >
                  <div style={{ fontWeight: 'bold', marginLeft: '-0.2rem' }}>
                    Add Pod Members
                  </div>
                  <div style={{ marginRight: '20px' }}>
                    {inputRowList.map(item => {
                      if (item.deleted != true) {
                        return (
                          <InputRow
                            rolesList={roleList}
                            onDelete={handleDeleteInputRow}
                            index={item.id}
                            handleInputChange={handleChangeInputRow}
                            key={item.id}
                          />
                        );
                      }
                      return <></>;
                    })}
                  </div>
                </Row>
              </Col>
            </Container>
          </ModalBody>
          <ModalFooter>
            <Container style={{ textAlign: 'left' }}>
              <Row
                style={{
                  marginBottom: '1rem',
                  marginTop: '1rem',
                }}
              >
                <Col
                  sm={8}
                  md={6}
                  onClick={handleAddInputRow}
                  style={{
                    color: '#004F71',
                    display: 'flex',
                    flexDirection: 'row',
                    marginLeft: '-.5rem',
                    marginTop: '-3rem',
                    cursor: 'pointer',
                  }}
                >
                  <Icon
                    icon={IconPlus}
                    style={{
                      marginLeft: '-0.2rem',
                      textAlign: 'center',
                      cursor: 'pointer',
                      justifyContent: 'center',
                    }}
                    height="1.5em"
                    width="1.5em"
                  />
                  <h4
                    style={{
                      color: '#004F71',
                      marginLeft: '.75rem',
                      marginTop: '.15rem',
                      cursor: 'pointer',
                    }}
                  >
                    {' '}
                    Add Pod Member
                  </h4>
                </Col>
                <Col
                  sm={12}
                  style={{
                    marginTop: '1.25rem',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                  }}
                >
                  <Button onClick={handleClose} variant="outlined">
                    Cancel
                  </Button>
                  <Button
                    style={{ marginLeft: '2rem', marginRight: '-1.6rem' }}
                    onClick={handleSaveClose}
                    variant="filled"
                    disabled={isDisabled}
                  >
                    Create Pod
                  </Button>
                </Col>
              </Row>
            </Container>
          </ModalFooter>
        </React.Fragment>
      </Modal>
    </>
  );
};

export default CreatePodButton;
